.highlight_difference {
  background-color: #4aa564;
}


#ScrubJaySearchOutputTable\:export {
  border-radius: var(--border-button-radius-no6fc3, 2px) !important;
  border: 2px solid var(--awsui-color-blue-600) !important;
  background: var(--awsui-color-white) !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-l-4vl6xu, 20px) !important;
  color: var(--awsui-color-blue-600) !important;
  font-weight: var(--font-button-weight-of7qcx, 700) !important;
  font-family: var(
                  --font-family-base-ua1f64,
                  'Amazon Ember',
                  'Helvetica Neue',
                  Roboto,
                  Arial
  ) !important;
  font-size: var(--font-body-m-size-sregvd, 14px) !important;
}

.ag-cell-value .cell-renderer-root-container .cell-renderer-main-content-interactive {
  max-width: initial;
}

.udc-theme .ag-cell {
  min-height: auto !important;
}

.udc-theme .ag-header-group-cell-label {
  justify-content: center !important;
}

.udc-theme .ag-header-cell-label {
  justify-content: center;
}

.headerCenter {
  text-align: center;
}

.cellCenter .ag-cell-wrapper {
  justify-content: center;
}

.ag-header span {
  white-space: normal !important;
}

.cell-renderer-root-container.text-cell-renderer-container .cell-renderer-content-text {
  white-space: normal;
}

.UDC-pagination {
  z-index: 1001;
}
